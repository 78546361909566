@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.sidebar {
  color: grey;
  padding-bottom: 10px;
  a {
    color: #b8c7ce;
  }
}
.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  > li {
    position: relative;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #3c4a5d;
    > a {
      padding: 12px;
      display: block;
      > .fa {
        width: 20px;
      }
    }
    .label {
      margin-top: 3px;
      margin-right: 5px;
    }
    .badge {
      margin-top: 3px;
      margin-right: 5px;
    }
    > .treeview-menu {
      margin: 0 1px;
      background: #2c3b41;
    }
  }
  li.header {
    padding: 10px 25px 10px 15px;
    font-size: 12px;
  }
  li {
    > a {
      > .fa-angle-left {
        width: auto;
        height: auto;
        padding: 0;
        margin-right: 10px;
        margin-top: 3px;
      }
      position: relative;
      text-decoration: none;
      color: #9faabc;
      text-transform: uppercase;
      font-size: 12px;
      > .pull-right {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -7px;
        transform: rotate(180deg);
      }
      > i {
        font-size: 18px;
      }
    }
  }
  li.active {
    > a {
      > .fa-angle-left {
        transform: rotate(-90deg);
      }
    }
    > .treeview-menu {
      display: block;
    }
  }
  .treeview-menu {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    .treeview-menu {
      padding-left: 20px;
      padding-left: 20px;
    }
    > li {
      margin: 0;
      padding: 5px;
      margin: 0;
      padding: 5px;
      > a {
        padding: 5px 5px 5px 32px;
        display: block;
        font-size: 12px;
        white-space: normal;
        padding: 5px 5px 5px 32px;
        display: block;
        font-size: 12px;
        white-space: normal;
        > .fa {
          width: 20px;
          width: 20px;
        }
        > .fa-angle-left {
          width: auto;
          width: auto;
        }
        > .fa-angle-down {
          width: auto;
          width: auto;
        }
      }
    }
  }
}
.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  background-color: #354151;
  .user-panel {
    white-space: nowrap;
    overflow: hidden;
  }
}

.expanded {
  display: block !important;
}
.treeview {
  cursor: pointer;
  > a {
    &:hover {
      background: #1e282c;
      color: #fff;
    }
  }
}

.treeview.active {
  background-color: #49ba8e;
  color: #fff;
  > a {
    color: #fff;
    &:hover {
      background-color: #49ba8e;
    }
  }
}

.sidebar-menu > .expanded-children {
  background: #1e282c;
  border-left: 3px solid #3c8dbc;
}

.closeMenu {
  position: fixed;
  top: 90px;
  right: 25px;
}

@include media-breakpoint-down(md) {
  .main-sidebar {
    width: 100%;
    padding-left: 15px;
    display: none;
  }

  .main-sidebar-show {
    display: block;
  }

  .main-sidebar-hide {
    display: none;
  }

}

@include media-breakpoint-up(md) {
  .main-sidebar {
    width: 230px;
    margin-left: 15px;
  }
}

