.panel {
    background-color: #f2f2f2;
}

.form-label, .form-check-label{
    font-size: 13px;
    color: #2f2f2f;
}

.panel {
    >h4 {
    text-align: center;
    border-bottom: 1px solid #CCC;
    }
    padding-top: 10px;
    margin-bottom: 40px;

    >section {
        padding: 20px;
        overflow-x: auto;
    }

    .show-applying-party {
        padding-top: 38px;
    }
}

.submit-btn {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.event-list {
    padding-bottom: 20px;
}

.text-dark{
    >.custom-control-label, .form-check-label{
        color: #2f2f2f !important;
    }
}

.divider {
    border-top: 2px solid #FFF;
    margin-bottom: 8px;
}

.ck-editor-group {
    flex-grow: 1;
}

.ck.ck-editor {
    width: 100%;
}