@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.content-wrapper {
  margin-top: 80px;
}

.nav-bar {
  display: block;
}

@include media-breakpoint-down(xs) {
  .container-fixed {
    position: fixed;
  }
}
