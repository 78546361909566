.forgot-password {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background-size: 100% 100%;
  }

  .id-card {
    min-height: calc(100vh - 50px);
  }

  .login-box {
    background: url("/dist/img/loginBoxBg.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 280px;
    height: 225px;
    margin: 0 auto;
  }

  .login-box-body {
    border-radius: 0px 0 5px 5px;
    background: url("/dist/img/loginid-bg.png") repeat;
    color: #666;
    width: 280px;
    margin: 0 auto;
  }

  .logintitle {
    padding: 10px 0 0 0;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    color: #364888;
  }

  .event-logo {
    background: #fff;
    text-align: center;
    margin: 10px 0;
    margin-right: 20px;
    margin: 15px 0;
  }

  #eventLogo {
    width: 186px;
  }

  .login-box-msg {
    margin: 0;
    text-align: center;
  }

  .loginusericon {
    text-align: center;
    margin: 15px 0;
  }

  .bms-admin {
    text-align: center;
    font-size: 18px;
    margin: 0;
    text-transform: unset;
    color: #354051;
  }

  .loginForm.resetPwd {
    padding: 8px !important;
  }

  .loginSubmit {
    background-color: rgb(216, 27, 34);
    color: #fff;
    padding: 10px 0;
    border-radius: 5px;
    border: 1px solid #b5060e;
    font-size: 14px;
    text-shadow: 0 1px 1px rgba(7, 6, 6, 106);
    background: #dd2029;
    background: -moz-linear-gradient(top, #dd2029 0%, #dc1a23 43%, #d51a23 72%, #d21a23 100%);
    background: -webkit-linear-gradient(top, #dd2029 0%, #dc1a23 43%, #d51a23 72%, #d21a23 100%);
    background: linear-gradient(to bottom, #dd2029 0%, #dc1a23 43%, #d51a23 72%, #d21a23 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dd2029', endColorstr='#d21a23',GradientType=0 );
  }

  .loginSubmit:hover {
    color: #fff;
  }

  .forgotpw {
    margin: 20px 0 0 0;
  }

  .forgotpw a {
    color: #59595b;
  }

  .login-box-msg {
    font-size: 13px;
    color: red;
    margin-bottom: 16px;
  }

  .invalid-feedback {
    margin-top: 0 !important;
    font-size: 65% !important;
  }

  .was-validated {
    .form-control {
      &:valid {
        border-color: #fff !important;
        background-image: none !important;
      }
    }
  }

  .text-center {
    display: flex;
    justify-content: center;
    font-size: 13px;
  }

  .password-hint {
    margin-bottom: 60px;
    border: 1px solid #c5c5c5;
    margin: 2px;
    border-radius: 4px;
    text-align: left;
    padding: 5px;
    margin-bottom: 8px;
    font-size: 13px;
  }

  .password-hint > ol {
    padding-left: 20px;
  }

  .password-hint p {
    margin-bottom: 8px;
  }

  .green {
    color: green;
  }

  .copyright-text {
    color: #fff;
    text-align: center;
    padding: 8px;
    padding-top: 30px;
    font-size: 10px;
    text-shadow: 0px 0px 2px #000;
    margin: 0;
    line-height: 12px;
  }
}
