.suggetion-list {
  height: 200px;
  overflow-y: auto;
  padding: 0;
}

.event-search {
  display: flex !important;
  justify-content: space-between !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
