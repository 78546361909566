@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.logo > img {
  max-height: 33px;
  margin: 7px;
}

.icon-bar {
  color: #3c8dbc;
}

.profile-img {
  width: 40px;
  height: 40px;
}
.user-role {
  display: flex;
  width: 280px;
}

.user-and-role {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  color: grey;
  > .username {
    font-weight: 700;
    color: #354052;
  }
}

#user-nav-dropdown:after {
  display: none;
}

.profile-dropdown {
  margin-top: 9px;
  margin-bottom: 14px;
  padding: 3px 5px;
  background: #c2c4c6;
  color: #fff;
  border-radius: 3px;
  text-shadow: 3px 2px 4px rgba(150, 150, 150, 1);
}

.user-role-wrapper {
  display: flex;
  justify-content: space-around;
}

.dropdown-menu {
  margin: 7px !important;
  width: 100% !important;
  border-radius: 0px !important;
  border-top: 0px !important;
}

.role {
  color: #354051;
  text-transform: uppercase;
}
.navbar {
  z-index: 999;
}

.mobile-menu {
  text-align: center;
  font-size: 16px;
  line-height: 46px;
  width: 100%;
  background-color: #CCC;
  margin-bottom: 20px;
  display: none;
}

@include media-breakpoint-down(md) {
  .main-sidebar-show {
    display: none;
  }

  .main-sidebar-hide {
    display: block;
  }
}